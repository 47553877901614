<template>
	<div class="page">
		
		<!-- 表单 -->
		<el-form class="form" label-width="90px">
			<div class="big_tit" style="margin:0">发货人</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货主电话" style="width:388px">
					<el-input class="el_inner_width" type="number" v-model="shipper_info.tel" clearable>
						<el-button @click="shipper_info_ser" type="success" slot="append">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货主信息" style="width:588px">
					<el-input class="el_inner_width" v-model="shipper_info.info_text" :disabled="true" clearable>
						<el-button @click="shipper_info_clear" type="success" slot="append">清空</el-button>
					</el-input>
				</el-form-item>
			</div>

			<div class="big_tit">车队长/司机/货车</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="车队长手机" style="width:360px">
					<el-input class="el_inner_width" v-model="truck_owner_info.tel">
						<el-button @click="truck_owner_ser" type="success" slot="append">查找</el-button>
						<el-button @click="truck_owner_clear" type="success" slot="append">清空</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="车队长信息" style="width:212px">
					<el-input class="el_inner_width" v-model="truck_owner_info.name" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="选择司机" style="width:392px">
					<el-select class="el_inner_width" v-model="driver_list.choosed">
						<el-option v-for="(item,index) of driver_list.data" :key="index" :label="`${item.driver_info.name}/${item.driver_info.tel}/${item.truck_info.truck_plate_num}`" :value="index"></el-option>
					</el-select>
				</el-form-item>
			</div>


			<div class="big_tit">基本数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物名称" style="width:388px">
					<el-autocomplete 
						class="el_inner_width"
						v-model="form.cargo_name"
						placeholder="请输入货物名称"
						:fetch-suggestions="used_cargo_names_init"
						@change="cargo_name_change"
						@select="cargo_name_change"
						clearable
					></el-autocomplete>
				</el-form-item>
				<el-form-item class="el_form_item" label="货物类型" style="width:588px">
					<el-select class="el_inner_width" v-model="form.cargo_type" clearable>
						<el-option v-for="(item,index) in cargo_type" :key="index" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货重(吨)" style="width:388px">
					<el-input class="el_inner_width" type="number" v-model="form.cargo_weight" placeholder="不填表示面议" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="体积(方)" style="width:588px">
					<el-input class="el_inner_width" type="number" v-model="form.cargo_volume" placeholder="不填表示面议" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">装货地信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="装货地" style="width:947px">
					<el-input v-model="form.case_addr_full_text" placeholder="装货地详细地址(地图选点)" disabled>
						<el-button slot="append" @click="addr_book_save(1)">保存地址</el-button>
						<el-button slot="append" @click="addr_book_list_open(1)">地址簿</el-button>
						<el-button slot="append" @click="case_addr_map_open">地图</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="联系人" style="width:388px">
					<el-input class="el_inner_width" v-model="form.case_link_man" placeholder="装货地联系人" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="联系电话" style="width:588px">
					<el-input class="el_inner_width" v-model="form.case_link_tel" type="number" placeholder="装货地联系电话" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">卸货地信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="卸货地" style="width:947px">
					<el-input v-model="form.aim_addr_full_text" placeholder="卸货地详细地址(地图选点)" disabled>
						<el-button slot="append" @click="addr_book_save(2)">保存地址</el-button>
						<el-button slot="append" @click="addr_book_list_open(2)">地址簿</el-button>
						<el-button slot="append" @click="aim_addr_map_open">地图</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="联系人" style="width:388px">
					<el-input class="el_inner_width" v-model="form.aim_link_man" placeholder="卸货地联系人" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="联系电话" style="width:588px">
					<el-input class="el_inner_width" v-model="form.aim_link_tel" type="number" placeholder="卸货地联系电话" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">运费相关 (说明: 如果不填运费则只能进行招投标, 如果填写运费可以直接抢单也可以指定也可以招投标)</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="运费(元)" style="width:388px">
					<el-input class="el_inner_width" type="number" v-model="form.freight_total" placeholder="货主需要支付给平台的金额" clearable>
						<el-button slot="append" style="width:70px">元</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="运输距离" style="width:588px">
					<el-input class="el_inner_width" v-model="form.distance" type="number" placeholder="单位(公里), 非必填" clearable>
						<el-button slot="append">公里</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="结算方式" style="width:388px">
					<el-select class="el_inner_width" v-model="form.settlement_type" clearable>
						<el-option label="按吨结算" value="1"></el-option>
						<el-option label="按方结算" value="2"></el-option>
						<el-option label="按趟结算" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="是否回程" style="width:588px">
					<el-select class="el_inner_width" v-model="form.is_need_return" clearable>
						<el-option label="需要回程" value="1"></el-option>
						<el-option label="不需要回程" value="2"></el-option>
					</el-select>
				</el-form-item>
			</div>


			<div class="big_tit">发到货时间</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="发车时间" style="width:388px">
					<el-date-picker 
						v-model="form.start_time" 
						type="datetime" 
						placeholder="选择日期时间" 
						style="width:280px"
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="到货时间" style="width:588px">
					<el-date-picker 
						v-model="form.arrived_time" 
						type="datetime" 
						placeholder="选择日期时间" 
						style="width:468px"
					></el-date-picker>
				</el-form-item>
			</div>


			<div class="big_tit">其他信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="上游单号" style="width:388px">
					<el-input class="el_inner_width" v-model="form.out_trade_num" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="组别编号" style="width:588px">
					<el-input class="el_inner_width" v-model="form.group1_num" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="运单备注" style="width:946px">
					<el-input v-model="form.mark" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
			</div>
		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="sub">确定添加</el-button>
			<el-button type="danger" @click="clear">清空数据</el-button>
		</div>

		<!-- 发货地选择 -->
		<el-dialog title="发货地选择" top="1vh" width="90%" :visible.sync="case_addr_map_choose.is_show">
			<mapChoose 
				style="height:700px" 
				v-if="case_addr_map_choose.is_show" 
				:old_data="case_addr_map_choose.old_data" 
				@choosed="case_addr_map_choosed"
			></mapChoose>
		</el-dialog>

		<!-- 卸货地选择 -->
		<el-dialog title="卸货地选择" top="1vh" width="90%" :visible.sync="aim_addr_map_choose.is_show">
			<mapChoose 
				style="height:700px" 
				v-if="aim_addr_map_choose.is_show" 
				:old_data="aim_addr_map_choose.old_data" 
				@choosed="aim_addr_map_choosed"
			></mapChoose>
		</el-dialog>
		
		<!-- 地址簿列表 -->
		<addrBookList 
			:is_show="addr_book_list.is_show" 
			:type="addr_book_list.type" 
			:user_num="addr_book_list.user_num" 
			@choosed="addr_book_list_choosed" 
		></addrBookList>
	</div>
</template>
<script>
	import truck_type_handle from '@/my/_truck_type_handle.js'
	import mapChoose from '@/components/map/amap/choose.vue'
	import addrBookList from '@/pages/app_user/addr_book/list.vue'
	export default {
		components:{
			mapChoose,
			addrBookList
		},
		data() {
			return {

				//表单
				form: {
					out_trade_num:'',//上游单号
					group1_num:'',//组别编号
					cargo_name:'',//货物名称
					cargo_type:'',//货物性质

					//发货省市区
					case_prov:{
						name:'',
						code:'',
					},
					case_city:{
						name:'',
						code:'',
					},
					case_county:{
						name:'',
						code:'',
					},
					case_link_man:'',//装货地联系人
					case_link_tel:'',//装货地联系电话
					case_addr:'',//装货地详情
					case_addr_full_text:'',//装货地完整字符串
					case_lng:'',//装货地经度
					case_lat:'',//装货地纬度
					start_time:null,//发车时间
					arrived_time:null,//到货时间

					//卸货省市区
					aim_prov:{
						name:'',
						code:'',
					},
					aim_city:{
						name:'',
						code:'',
					},
					aim_county:{
						name:'',
						code:'',
					},
					aim_link_man:'',//卸货地联系人
					aim_link_tel:'',//卸货地联系电话
					aim_addr:'',//卸货地详情
					aim_addr_full_text:"",//卸货地完整字符串
					aim_lng:'',//卸货地经度
					aim_lat:'',//卸货地纬度
					freight_total:null,//运费
					settlement_type:'3',//结算方式(0:未知,1:按吨数,2:按方数,3:按趟)
					is_need_return:'2',//是否回程(1:需要,2:不需要)
					distance:'',//运输距离(单位:公里)
					cargo_weight:'',//货物重量
					cargo_volume:'',//货物体积
					need_truck_type:null,//租用货车类型
					mark:'',//备注
				},

				//货主
				shipper_info:{
					tel:'',//货主电话
					info_text:'',//货主信息
					user_num:'',//货主用户编号
				},

				//收款人
				truck_owner_info:{
					tel:'',//收款人电话
					name:'',//收款人姓名
					user_num:'',//收款人用户编号
				},

				//司机列表
				driver_list:{
					data:[],
					choosed:''
				},

				//货物类型
				cargo_type:[],

				//车型
				truck_type:[],

				//使用过的货物名称
				used_cargo_names:[],

				//发货地地图选择
				case_addr_map_choose:{
					is_show:false,
					old_data:{
						prov_code:'',
						city_code:'',
						county_code:'',
						addr:'',
					}
				},

				//卸货地地图选择
				aim_addr_map_choose:{
					is_show:false,
					old_data:{
						prov_code:'',
						city_code:'',
						county_code:'',
						addr:'',
					}
				},
				
				//地址簿列表
				addr_book_list:{
					is_show:0,
					user_num:'',
					type:0,
				}
			}
		},
		created() {

			//使用过的货物名称初始化
			this.set_used_cargo_name();
			
			//获取参数
			this.get_creat_para();

			//高德地图插件初始化
			this.$amap.load({
				plugins:[
					'AMap.Driving',
				]
			}).then((AMap)=>{

				//初始化
				this.amapDriving = new AMap.Driving({
					policy:AMap.DrivingPolicy.LEAST_TIME,//路线规划策略
				})
			})
		},
		methods: {

			//保存地址
			addr_book_save(type){

				if(!this.shipper_info.user_num.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"货主为空,请输入"
					});
					return;
				}

				let form={
					mod:'app_user',
					ctr:'addr_book_add_pub',
					user_num:this.shipper_info.user_num,
					type:type,
				}
				switch(type){

					case 1://发货地
						if(!this.form.case_prov.name){
							this.$my.other.msg({
								type:'warning',
								str:"请选择装货地"
							});
							return;
						}
						form.prov_name=this.form.case_prov.name
						form.prov_code=this.form.case_prov.code
						form.city_name=this.form.case_city.name
						form.city_code=this.form.case_city.code
						form.county_name=this.form.case_county.name
						form.county_code=this.form.case_county.code
						form.addr_info=this.form.case_addr
						form.link_man=this.form.case_link_man
						form.link_tel=this.form.case_link_tel
						form.lat=this.form.case_lat
						form.lng=this.form.case_lng
						break;

					case 2://卸货地
						if(!this.form.aim_prov.name){
							this.$my.other.msg({
								type:'warning',
								str:"请选择卸货地"
							});
							return;
						}
						form.prov_name=this.form.aim_prov.name
						form.prov_code=this.form.aim_prov.code
						form.city_name=this.form.aim_city.name
						form.city_code=this.form.aim_city.code
						form.county_name=this.form.aim_county.name
						form.county_code=this.form.aim_county.code
						form.addr_info=this.form.aim_addr
						form.link_man=this.form.aim_link_man
						form.link_tel=this.form.aim_link_tel
						form.lat=this.form.aim_lat
						form.lng=this.form.aim_lng
						break;
				}

				//调用接口
				this.$my.net.req({
					data:form,
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'保存成功'
						});
					}
				});
			},
			addr_book_list_open(type){
				if(!this.shipper_info.user_num.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"货主为空,请输入"
					});
					return;
				}
				this.addr_book_list.type=type
				this.addr_book_list.user_num=this.shipper_info.user_num
				this.addr_book_list.is_show++
			},
			addr_book_list_choosed(obj){//
				
				//置入
				switch(this.addr_book_list.type){

					case 1://发货地
						this.form.case_prov.name=obj.prov_name
						this.form.case_prov.code=obj.prov_code
						this.form.case_city.name=obj.city_name
						this.form.case_city.code=obj.city_code
						this.form.case_county.name=obj.county_name
						this.form.case_county.code=obj.county_code
						this.form.case_addr=obj.addr_info
						this.form.case_addr_full_text=`${obj.prov_name} / ${obj.city_name} / ${obj.county_name} / ${obj.addr_info}`
						this.form.case_link_man=obj.link_man
						this.form.case_link_tel=obj.link_tel
						this.form.case_lat=obj.lat
						this.form.case_lng=obj.lng
						break;

					case 2://卸货地
						this.form.aim_prov.name=obj.prov_name
						this.form.aim_prov.code=obj.prov_code
						this.form.aim_city.name=obj.city_name
						this.form.aim_city.code=obj.city_code
						this.form.aim_county.name=obj.county_name
						this.form.aim_county.code=obj.county_code
						this.form.aim_addr=obj.addr_info
						this.form.aim_addr_full_text=`${obj.prov_name} / ${obj.city_name} / ${obj.county_name} / ${obj.addr_info}`
						this.form.aim_link_man=obj.link_man
						this.form.aim_link_tel=obj.link_tel
						this.form.aim_lat=obj.lat
						this.form.aim_lng=obj.lng
						break;
				}
				this.distance_calc()
			},

			//发货地地址选择相关
			case_addr_map_open(){
				this.case_addr_map_choose.is_show=true;
				this.case_addr_map_choose.old_data.prov_code=this.form.case_prov.code
				this.case_addr_map_choose.old_data.city_code=this.form.case_city.code
				this.case_addr_map_choose.old_data.county_code=this.form.case_county.code
				this.case_addr_map_choose.old_data.addr=this.form.case_addr?this.form.case_addr:''
			},
			case_addr_map_choosed(rel){
				this.case_addr_map_choose.is_show=false;
				this.form.case_prov.name=rel.prov_name
				this.form.case_prov.code=rel.prov_code
				this.form.case_city.name=rel.city_name
				this.form.case_city.code=rel.city_code
				this.form.case_county.name=rel.county_name
				this.form.case_county.code=rel.county_code
				this.form.case_addr=rel.addr_info
				this.form.case_addr_full_text=`${rel.prov_name} / ${rel.city_name} / ${rel.county_name} / ${rel.addr_info}`
				this.form.case_lng=rel.lng
				this.form.case_lat=rel.lat
				this.distance_calc()
			},

			//卸货地地址选择相关
			aim_addr_map_open(){
				this.aim_addr_map_choose.is_show=true;
				this.aim_addr_map_choose.old_data.prov_code=this.form.aim_prov.code
				this.aim_addr_map_choose.old_data.city_code=this.form.aim_city.code
				this.aim_addr_map_choose.old_data.county_code=this.form.aim_county.code
				this.aim_addr_map_choose.old_data.addr=this.form.aim_addr?this.form.aim_addr:''
			},
			aim_addr_map_choosed(rel){
				this.aim_addr_map_choose.is_show=false;
				this.form.aim_prov.name=rel.prov_name
				this.form.aim_prov.code=rel.prov_code
				this.form.aim_city.name=rel.city_name
				this.form.aim_city.code=rel.city_code
				this.form.aim_county.name=rel.county_name
				this.form.aim_county.code=rel.county_code
				this.form.aim_addr=rel.addr_info
				this.form.aim_addr_full_text=`${rel.prov_name} / ${rel.city_name} / ${rel.county_name} / ${rel.addr_info}`
				this.form.aim_lng=rel.lng
				this.form.aim_lat=rel.lat
				this.distance_calc()
			},

			//计算运输距离
			distance_calc(){

				//发到货地经纬度齐备时计算导航距离
				if(!(this.form.case_lng&&this.form.case_lat&&this.form.aim_lng&&this.form.aim_lat)){
					return;
				}

				//计算导航距离
				this.amapDriving.search(
					new AMap.LngLat(this.form.case_lng,this.form.case_lat),//起点
					new AMap.LngLat(this.form.aim_lng,this.form.aim_lat),//终点
					(status,result)=>{
						if(status=='complete'&&result.info=='OK'){
							this.form.distance=(result.routes[0].distance/1000).toFixed(3)
						}
					}
				)
			},

			//提交
			sub(){

				let post_data={
					mod:'truck_tord_real_time',
					ctr:'tord_act_add_by_admin',
					case_lat:this.form.case_lat,
					case_lng:this.form.case_lng,
					aim_lat:this.form.aim_lat,
					aim_lng:this.form.aim_lng,
				};

				if(!this.shipper_info.user_num){//货主
					this.$my.other.msg({
						type:"warning",
						str:'缺少货主信息'
					});
					return;
				}else post_data.shipper_num=this.shipper_info.user_num

				//收款人
				if(!this.truck_owner_info.user_num){
					this.$my.other.msg({
						type:"warning",
						str:"缺少收款人信息"
					});
					return;
				}else post_data.truck_owner_num=this.truck_owner_info.user_num

				//货主收款人校验
				if(post_data.shipper_num == post_data.truck_owner_num){
					this.$my.other.msg({
						type:"warning",
						str:"货主和车队长不能是同一人"
					});
					return;
				}

				//司机和货车
				if(!this.$my.check.is_num(this.driver_list.choosed)){
					this.$my.other.msg({
						type:"warning",
						str:"请选择司机"
					});
					return;
				}else{
					let driver_info=this.driver_list.data[this.driver_list.choosed]
					if(!driver_info){
						this.$my.other.msg({
							type:"warning",
							str:"司机选择结果有误,请刷新后再试"
						});
						return;
					}
					post_data.driver_num=driver_info.driver_num
					post_data.truck_plate_num=driver_info.truck_info.truck_plate_num
				}

				//货主司机校验
				if(post_data.shipper_num == post_data.driver_num){
					this.$my.other.msg({
						type:"warning",
						str:"货主和司机不能是同一人"
					});
					return;
				}

				if(!this.form.cargo_name.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少货物名称'
					});
					return;
				}else post_data.cargo_name=this.form.cargo_name.trim()

				if(!this.form.cargo_type.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少货物性质'
					});
					return;
				}else post_data.cargo_type=this.form.cargo_type.trim()

				if(!(this.$my.check.is_num(this.form.cargo_weight)&&this.form.cargo_weight>0)){//货物重量
					this.$my.other.msg({
						type:"warning",
						str:'缺少货物重量'
					});
					return;
				}else post_data.cargo_weight=this.form.cargo_weight

				if(this.$my.check.is_num(this.form.cargo_volume)&&this.form.cargo_volume>0){//货物体积
					post_data.cargo_volume=this.form.cargo_volume
				}

				//装货地省市县
				if(!(this.form.case_prov.name&&this.form.case_city.name&&this.form.case_county.name)){
					this.$my.other.msg({
						type:'warning',
						str:"装货地数据为空,请选择装货地"
					});
					return;
				}else{
					post_data.case_prov=this.form.case_prov.name
					post_data.case_city=this.form.case_city.name
					post_data.case_county=this.form.case_county.name
					post_data.case_prov_code=this.form.case_prov.code
					post_data.case_city_code=this.form.case_city.code
					post_data.case_county_code=this.form.case_county.code
				}

				//装货地联系人/联系电话
				if(this.form.case_link_tel && !this.$my.check.is_tel(this.form.case_link_tel)){
					this.$my.other.msg({
						type:'warning',
						str:"装货地联系电话格式有误"
					});
					return;
				}else{
					post_data.case_link_tel=this.form.case_link_tel
					post_data.case_link_man=this.form.case_link_man
				}

				//装货地详细地址
				if(this.form.case_addr.trim()){
					post_data.case_addr=this.form.case_addr.trim()
				}

				//卸货地省市县
				if(!(this.form.aim_prov.name && this.form.aim_city.name && this.form.aim_county.name)){
					this.$my.other.msg({
						type:'warning',
						str:"到货地数据为空,请选择到货地"
					});
					return;
				}else{
					post_data.aim_prov=this.form.aim_prov.name
					post_data.aim_city=this.form.aim_city.name
					post_data.aim_county=this.form.aim_county.name
					post_data.aim_prov_code=this.form.aim_prov.code
					post_data.aim_city_code=this.form.aim_city.code
					post_data.aim_county_code=this.form.aim_county.code
				}

				//卸货地联系电话
				if(this.form.aim_link_tel && !this.$my.check.is_tel(this.form.aim_link_tel)){
					this.$my.other.msg({
						type:'warning',
						str:"卸货地联系电话格式有误"
					});
					return;
				}else{
					post_data.aim_link_tel=this.form.aim_link_tel
					post_data.aim_link_man=this.form.aim_link_man
				}
				
				//卸货地详情
				if(this.form.aim_addr.trim()){
					post_data.aim_addr=this.form.aim_addr.trim()
				}

				//运费
				if(!(this.$my.check.is_num(this.form.freight_total) && this.form.freight_total>0)){
					this.$my.other.msg({
						type:"warning",
						str:"运费格式有误"
					});
					return;
				}else post_data.freight_total=this.form.freight_total

				//运输距离(单位:公里)
				post_data.distance=this.form.distance

				//结算方式(0:未知,1:按吨数,2:按方数,3:按趟)
				post_data.settlement_type=this.form.settlement_type

				//是否回程(1:需要,2:不需要)
				post_data.is_need_return=this.form.is_need_return

				//发车时间和到货时间
				let start_time=this.form.start_time.getTime()
				let arrived_time=this.form.arrived_time.getTime()
				if(start_time){
					post_data.start_time=(start_time/1000).toFixed(0)
				}
				if(!start_time && arrived_time){
					this.$my.other.msg({
						type:"warning",
					});
					return;
				}else post_data.arrived_time=(arrived_time/1000).toFixed(0)

				//上游单号
				if(this.form.out_trade_num.trim()){
					post_data.out_trade_num=this.form.out_trade_num.trim()
				}

				//组编号1(用户自定义打组)
				if(this.form.group1_num.trim()){
					post_data.group1_num=this.form.group1_num.trim()
				}

				//租用货车类型
				if(this.form.need_truck_type){
					post_data.need_truck_type=this.form.need_truck_type[0]
					post_data.need_truck_type2=this.form.need_truck_type[1]
				}

				//备注
				if(this.form.mark.trim()){
					post_data.mark=this.form.mark.trim()
				}

				//提交数据
				this.$my.net.req({
					data:post_data,
					callback:(data)=>{

						//添加成功
						this.$my.other.confirm({
							content:"添加成功,是否前往运单列表?",
							confirm:()=>{

								this.$router.push({
									path:'/pages/transit_real_time/tord_act_list'
								});
							}
						});
					}
				});
			},

			//查找货主
			shipper_info_ser(){

				//格式判断
				if(!this.$my.check.is_tel(this.shipper_info.tel)){
					this.$my.other.msg({
						type:"warning",
						str:'货主电话号码格式有误'
					});
					return;
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_list_by_admin',
						is_get_company_info:1,
						tel:this.shipper_info.tel
					},
					callback:(data)=>{
						
						data=data.list[0];

						if(!data){
							this.$my.other.msg({
								type:"warning",
								str:'不存在的货主'
							});
							return;
						}

						if(data.name=='未实名'){//这里是为了兼容 翔达 的业务逻辑不能使用 real_status 进行判断
							this.$my.other.msg({
								type:"warning",
								str:'此用户未完成实名认证'
							});
							return;
						}

						this.shipper_info.user_num=data.user_num
						this.shipper_info.info_text=`${data.name} / ${data.company_info.name}`
					}
				});
			},

			//清空货主信息
			shipper_info_clear(){
				this.shipper_info.tel=''
				this.shipper_info.info_text=''
				this.shipper_info.user_num=''
			},

			//查找车队长
			truck_owner_ser(){
				
				//格式判断
				if(!this.$my.check.is_tel(this.truck_owner_info.tel)){
					this.$my.other.msg({
						type:"warning",
						str:'司机电话号码格式有误'
					});
					return;
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'truck_owner_info',
						truck_owner_tel:this.truck_owner_info.tel
					},
					callback:(data)=>{

						if(data.name=='未实名'){
							this.$my.other.msg({
								type:"warning",
								str:'此用户未完成实名认证'
							});
							return;
						}

						this.truck_owner_info.user_num=data.user_num
						this.truck_owner_info.name=data.name

						//读取此车队长绑定的司机列表
						this.$my.net.req({
							data:{
								mod:'truck_driver',
								ctr:'driver_bind_list_by_admin',
								truck_owner_num:this.truck_owner_info.user_num,
								is_get_driver_info:1,
								is_get_truck_info:1,
								truck_status:2,
								truck_is_def:1
							},
							callback:(data)=>{

								for(var i=0;i<data.list.length;i++){
									if(!(data.list[i].driver_info && data.list[i].truck_info)){
										data.list.splice(i,1)
									}
								}

								this.driver_list.data=data.list
								this.driver_list.choosed=''
							}
						});
					}
				});
			},

			//清空收款人信息
			truck_owner_clear(){
				this.truck_owner_info.tel=''
				this.truck_owner_info.name=''
				this.truck_owner_info.user_num=''
				this.driver_list.data=[]
				this.driver_list.choosed=''
			},

			//清空本页数据
			clear(){

				this.$my.other.confirm({
					content:"点击确定清空本页数据",
					confirm:()=>{

						//清空货源数据
						this.form.out_trade_num="";
						this.form.group1_num="";
						this.form.cargo_name="";
						this.form.cargo_type="";
						
						//发货省市区
						this.form.case_prov={
							name:'',
							code:'',
						}
						this.form.case_city={
							name:'',
							code:'',
						}
						this.form.case_county={
							name:'',
							code:'',
						}
						this.form.case_link_man=''//装货地联系人
						this.form.case_link_tel=''//装货地联系电话
						this.form.case_addr=''//装货地详情
						this.form.case_addr_full_text=''//装货地完整字符串
						this.form.case_lng=''//装货地经度
						this.form.case_lat=''//装货地纬度
						this.form.start_time=null//发车时间
						this.form.arrived_time=null//到货时间

						//卸货省市区
						this.form.aim_prov={
							name:'',
							code:'',
						}
						this.form.aim_city={
							name:'',
							code:'',
						}
						this.form.aim_county={
							name:'',
							code:'',
						}
						this.form.aim_link_man=''//卸货地联系人
						this.form.aim_link_tel=''//卸货地联系电话
						this.form.aim_addr=''//卸货地详情
						this.form.aim_addr_full_text=""//卸货地完整字符串
						this.form.aim_lng=''//卸货地经度
						this.form.aim_lat=''//卸货地纬度
						this.form.freight_total=null//运费
						this.form.settlement_type='3'//结算方式(0=未知,1=按吨数,2=按方数,3=按趟)
						this.form.is_need_return='2'//是否回程(1=需要,2=不需要)
						this.form.distance=''//运输距离(单位=公里)
						this.form.cargo_weight=''//货物重量
						this.form.cargo_volume=''//货物体积
						this.form.mark=''//备注

						//清空货主信息
						this.shipper_info_clear()

						//清空收款人数据
						this.truck_owner_clear()
					}
				});
			},

			//获取创建参数
			get_creat_para(){

				//尝试从缓存中取出
				let creat_para=this.$my.cache.get('cargo_creat_page_creat_para');

				//如果取出成功
				if(creat_para){
					this.truck_type=creat_para.truck_type
					this.cargo_type=creat_para.cargo_type
					return
				}

				//如果取出失败,去服务器读取
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'cargo_creat_para'
					},
					callback:(data)=>{
						
						//格式化车型数据
						let truck_type=truck_type_handle.for_el_cascader(data.truck_type);
						
						//缓存之
						this.$my.cache.set({
							cargo_creat_page_creat_para:{
								truck_type:truck_type,
								cargo_type:data.cargo_type
							}
						});

						//置入
						this.truck_type=truck_type
						this.cargo_type=data.cargo_type
					}
				})
			},

			//置入用过的输入内用
			used_cargo_names_init(queryString,cb) {
				var createFilter=function(queryString){
					return (restaurant) => {
						return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
					};
				}
				var used_cargo_names = this.used_cargo_names;
				var results = queryString ? used_cargo_names.filter(createFilter(queryString)) : used_cargo_names;
				cb(results);
			},

			//使用过的货物名称初始化
			set_used_cargo_name(){
				let used_cargo_name=window.localStorage.getItem('cargo_creat_page_used_cargo_names');
				if(used_cargo_name){
					used_cargo_name=JSON.parse(used_cargo_name);
				}else used_cargo_name=[];
				this.used_cargo_names=[...used_cargo_name];
			},

			//货物名称输入
			cargo_name_change(){

				//查看此输入内容是否已收录
				let hasinputed=false;
				let used_cargo_names=[...this.used_cargo_names];
				for(var i in used_cargo_names){
					
					let item=used_cargo_names[i]

					//已收录,挪到第一位
					if(this.form.cargo_name==item.value){
						
						//删除原数组中对应的元素
						this.used_cargo_names.splice(i,1);

						//在原数组中新增此元素,并放到第一个未知
						this.used_cargo_names.unshift({
							value:this.form.cargo_name
						});
						
						//已收录
						hasinputed=true;
						break;
					}
				}

				//如果没有收录
				if(!hasinputed){

					//收录之
					this.used_cargo_names.unshift({
						value:this.form.cargo_name
					});
				}

				//保存到本地硬盘
				window.localStorage.setItem('cargo_creat_page_used_cargo_names',JSON.stringify(this.used_cargo_names))
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.bottom_btns{
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: 100%;
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form{
		height: calc(100% - 100px);
		overflow-y:auto;
		padding:15px;
	}

	.page{
		position:relative;
		background-color: #fff;
		border-radius: 10px;
		height: 100%;
		width: 1040px;
		overflow: auto;
		margin: auto;
	}

</style>